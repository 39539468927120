.input-range {
  .input-range__slider {
    height: 1.5rem;
    width: 1.5rem;
    margin-top: -0.9rem;
  }
  .input-range__label--value {
    top: -2.2rem;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.6rem;
  }
}
