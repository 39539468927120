.react-datepicker-wrapper {
  input {
    border: 1px solid;
    height: 42px;
    padding: 0 16px 0 46px;
    border-color: #cdcdcd;
    border-radius: 6px;
    &:focus,
    &:active {
      border-color: #002d78;
      outline: none;
    }
    &.invalid {
      border-color: red;
    }
  }
}
