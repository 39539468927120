.quill {
  width: 100%;
  & img {
    height: 300px;
    width: 400px;
  }
  .ql-container {
    height: 400px;
  }
}
